<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ uiTotalProducts }} results found
              </div>
            </div>
            <div class="view-options d-flex">
              <b-button
            variant="primary"
            tag="a"
            class="btn-wishlist mr-1"
           
            @click="_new()"
          >
            
            <span>New</span>
          </b-button>

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="FilterSortByOptions.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in uiSortByOptions"
                  :key="sortOption.value"
                  @click="FilterSortByOptions=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>
              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
   <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="uiSearchBY"
              placeholder="Search Product"
              class="search-product"
              @input="_FilterSearchBy()"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="product in uiProducts"
        :key="product._id"
        class="ecommerce-card"
        no-body
      >
                <b-link :to="{ name: 'apps-e-commerce-product-details', params: { id: product.postId , product: product } }">

        <div class="item-img text-center" :style="getImage(product)">
          <!--<b-img
              :alt="`${product._id}`"
              fluid
              class="card-img-top;"
              :src="product.images[0]"
            />-->
        </div>
                  </b-link>


        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>
            <div>
              <h6 class="item-price">
                ${{ product.price }}
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'apps-e-commerce-product-details', params: { slug: product.slug } }"
            >
              {{ product.name }}
            </b-link>
           
          </h6>
          <b-card-text class="item-description">
            {{ product.description }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                ${{ product.price }}
              </h4>
            </div>
          </div>
          <div>
</div>
        
     
             <b-button
            variant="light"
            tag="a"
            class="btn-cart"
            @click="boosterProduit(product)"
          >
            <feather-icon
              icon="TrendingUpIcon"
              class="mr-50"
            />
            <span>Booster</span>
          </b-button>
               <b-button
            variant="primary"
            tag="a"
            class="btn-wishlist"
            @click="_edit(product)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              :class="{'text-danger': product.isVerified}"
            />
            <span>Edit</span>
          </b-button>
          <b-button
            variant="light"
            tag="a"
            class="btn-cart"
            @click="_delete(product)"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Delete</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="uiPage"
            :total-rows="uiTotalProducts"
            :per-page="uiPerPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
     <!-- <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />-->
       <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              Filters
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>

          <!-- Multi Range -->
          <div class="multi-range-price">
            <h6 class="filter-title mt-0">
              Multi Range
            </h6>
            <b-form-radio-group
              v-model="uiFilterPriceRange"
              class="price-range-defined-radio-group"
              stacked
               @change="_FilterSearchByPriceRange()"
              :options="uiPriceRange"
            />
          </div>

          <!-- Price Slider -->
       <!--   <div class="price-slider">
            <h6 class="filter-title">
              Price Range
            </h6>
            <vue-slider
              v-model="filters.priceRange"
              @change="slidersizeprice"
              :max=30000
              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </div>-->

          <!-- Categories -->
          <div class="product-categories">
            <h6 class="filter-title">
              Categories
            </h6>
            <b-form-radio-group
              v-model="uiFilterCategory"
              class="categories-radio-group"
              stacked
              @change="_FilterSearchByCategory()"
              :options="uiCategories"
            />
          </div>

          <!-- Brands -->
       <!--   <div class="brands">
            <h6 class="filter-title">
              Brands
            </h6>
            <b-form-radio-group
              v-model="filters.brands"
              class="brands-radio-group"
              stacked
              :options="filterOptions.brands"
            />
          </div>
-->
          <!-- Ratings -->
          <div class="ratings">
            <h6 class="filter-title">
              Ratings
            </h6>
            <div
              v-for="rating in uiRatings"
              :key="rating.rating"
              class="ratings-list"
            >
              <b-link>
                <div class="d-flex">
                  <feather-icon
                    v-for="star in 5"
                    :key="star"
                    icon="StarIcon"
                    size="17"
                    :class="[{'fill-current': star <= rating.rating}, star <= rating.rating ? 'text-warning' : 'text-muted']"
                  />
                  <span class="ml-25">&amp; up</span>
                </div>
              </b-link>
              <div class="stars-received">
                <span>{{ rating.count }}</span>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
    </portal>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'

import {
  BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'
import ProductsMixin from "../product.mixin.js";
import CategoriesMixin from "../category.mixin.js";

import RatingsMixin from "../ratings.mixin.js";
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  mixins: [ProductsMixin, CategoriesMixin, RatingsMixin],

  directives: {
    Ripple,
  },
  components: {
    VueSlider,
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,

    // SFC
    ShopLeftFilterSidebar,
  },
  data () {
    return  {
     uiProducts : [],
     uiTotalProducts : 0,
     uiCategories : [],
     uiFilterCategory : 0,
     uiRatings : [],
     uiFilterPriceRange : 'all',
     uiPriceRange: [
      { text: 'All', value: 'all' },
      { text: '<= $10', value: '<=10' },
      { text: '$10 - $100', value: '10-100' },
      { text: '$100 - $500', value: '100-500' },
      { text: '$500 - $2000', value: '500-2000' },
      { text: '>= $2000', value: '>=2000' },
      ],
     uiSortByOptions: [
      { text: 'Featured', value: 'featured' },
      { text: 'Lowest', value: 'price-asc' },
      { text: 'Highest', value: 'price-desc' },
      ],
     FilterSortByOptions : { text: 'Featured', value: 'featured' },
     uiPage:1,
     uiPerPage:9,
     uiSearchBY:"",
     uiProductsAllSearched: []
    }
  },

  mounted() {
          store.dispatch('app-ecommerce/fetchProducts')
          this.uiProducts = []
          // uiProducts va contenir les produits visualisé à une instant t dans une page : 9 produits au maximum

          for(let j = 0 ; j < 9 ; j++) {
            if(this.products[j]!==undefined)
              this.uiProducts.push(this.products[j])
          }

          this.uiProductsAllSearched = this.products.slice()

          this.uiTotalProducts = this.products.length

          var tabDisplay = []
          let fin ;
          if (1*9 > this.uiProducts.length)
            fin = this.uiProducts.length
          else 
            fin = 1*9 

          for(let j = 0 ; j < fin ; j++) {
            if(this.uiProducts[j]!==undefined)
              tabDisplay.push(this.uiProducts[j])
          }
        
          this.uiProducts = tabDisplay

          this.uiCategories = this.categories

          this.uiRatings = this.ratings
  },

    watch: {
     uiPage: function() {
           if(this.FilterSortByOptions.value==="price-asc") {
            this.uiProductsAllSearched.sort(function (a, b) {
              return a.price - b.price;
            });
         }
        if(this.FilterSortByOptions.value==="price-desc") {
            this.uiProductsAllSearched.sort(function (a, b) {
              return b.price - a.price;
            });
        }
          var tabDisplay = []
          let fin ;
          if (this.uiPage*9 > this.uiProductsAllSearched.length)
            fin = this.uiProductsAllSearched.length
          else 
            fin = this.uiPage*9 
          for(let j = (this.uiPage-1)*9 ; j < fin ; j++) {
            if(this.uiProductsAllSearched[j]!==undefined)
              tabDisplay.push(this.uiProductsAllSearched[j])
          }
          this.uiProducts = tabDisplay
     },

     FilterSortByOptions: function() {
        this._filterby_cat_search_sort()
     },
     
     products: function() {       
          this.uiProducts = []
          for(let j = 0 ; j < 9 ; j++) {
            if(this.products[j]!==undefined)
              this.uiProducts.push(this.products[j])
          }
          this.uiProductsAllSearched = this.products.slice()

          this.uiTotalProducts = this.products.length

          var tabDisplay = []
          let fin ;
          if (1*9 > this.uiProducts.length)
            fin = this.uiProducts.length
          else fin = 1*9 
          for(let j = 0 ; j < fin ; j++) {
            if(this.uiProducts[j]!==undefined)
              tabDisplay.push(this.uiProducts[j])
          }
        
          this.uiProducts = tabDisplay
    },
     
     categories: function() {
       this.uiCategories = this.categories
    },

      ratings: function() {
        this.uiRatings = this.ratings
    }
  },

    methods : {
      boosterProduit(produit){

      },
      getImage(product){
          return 'background-size: cover!important;background-position: center!important; background-repeat: no-repeat; background-image:url('+product.images[0]+')'
      },
      _new() {
         this.$router.push({
              name: "apps-e-commerce-product-add"
            });
      },
      _FilterSearchBy() {
          this._filterby_cat_search_sort()
      },
      _FilterSearchByPriceRange() {
          this._filterby_cat_search_sort()
      },
      _FilterSearchByCategory() {
          this._filterby_cat_search_sort()
      },
      _filterby_cat_search_sort() {
         
         // filtre pour le mot saisi
         this.uiProducts = this.products.filter(x =>
          x.name.toLowerCase().includes(this.uiSearchBY.toLowerCase())
          );

         // filtre pour categorie choisi
         if(this.uiFilterCategory!=0) {
            let tab_after_filter_cat = []
            for(let z=0; z<this.uiProducts.length; z++) {
              if(this.uiProducts[z].category==this.uiCategories[this.uiFilterCategory].text) {
                   tab_after_filter_cat.push(this.uiProducts[z])
                }
            }
             this.uiProducts =  tab_after_filter_cat
          }

          // filtre par prix choisi intervalle
            let tab_after_filter_price = []
           if(this.uiFilterPriceRange=="all") {
              console.log("all")
            }else if(this.uiFilterPriceRange.includes("-")){
              let min_max = this.uiFilterPriceRange.split("-")
              let min = min_max[0]
              min = parseInt(min);
              let max = min_max[1]
              max = parseInt(max);
               for(let y=0; y < this.uiProducts.length; y++) {
                  if(min<=this.uiProducts[y].price && max>=this.uiProducts[y].price) {
                    tab_after_filter_price.push(this.uiProducts[y])
                  }
               }
            this.uiProducts = tab_after_filter_price
            }else{
              if(this.uiFilterPriceRange.includes("<=")){
                let nbrmax = this.uiFilterPriceRange.substring(2);
                nbrmax = parseInt(nbrmax);
                for(let y=0; y < this.uiProducts.length; y++) {
                  if(nbrmax>=this.uiProducts[y].price) {
                    tab_after_filter_price.push(this.uiProducts[y])
                  }
               }
              this.uiProducts = tab_after_filter_price
               
              } 
              if(this.uiFilterPriceRange.includes(">=")){
                let nbrmin = this.uiFilterPriceRange.substring(2);
                nbrmin = parseInt(nbrmin);
                for(let y=0; y < this.uiProducts.length; y++) {
                  if(nbrmin<=this.uiProducts[y].price) {
                    tab_after_filter_price.push(this.uiProducts[y])
                  }
               }
              this.uiProducts = tab_after_filter_price
              }    
            }
         let res_filtring = this.uiProducts.length
         this.uiProductsAllSearched = this.uiProducts.slice()
 
         // filtre par prix asc ou desc  
         if(this.FilterSortByOptions.value==="price-asc") {
            this.uiProducts.sort(function (a, b) {
              return a.price - b.price;
            });
        }
        if(this.FilterSortByOptions.value==="price-desc") {
            this.uiProducts.sort(function (a, b) {
              return b.price - a.price;
            });
        }
          var tabDisplay = []
          let fin ;
          if (1*9 > this.uiProducts.length)
            fin = this.uiProducts.length
          else fin = 1*9 
          for(let j = 0 ; j < fin ; j++) {
            if(this.uiProducts[j]!==undefined)
              tabDisplay.push(this.uiProducts[j])
          }
        
          this.uiTotalProducts = res_filtring
          this.uiProducts = tabDisplay
      },
      _delete(item) {
        this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pouvez pas annuler votre action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        confirmButtonColor: 'red',
        cancelButtonColor: "green",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
            store.dispatch('app-ecommerce/removeProduct', item).then(data => {
              if(data!=="error") {
                this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Product successfully deleted',
                  icon: "TrashIcon",
                  variant: 'success',
                },
              })
              setTimeout(function(){ 
                location.reload()
               }, 1000);

              }else{
                this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed delete',
                  icon: "TrashIcon",
                  variant: 'danger',
                },
              })
              }
            })
        
        } else {
           this.$toast({
               component: ToastificationContent,
                props: {
                  title: 'Annuler la suppression ',
                  icon: "TrashIcon",
                  variant: 'danger',
                },
            })
        }
      });
      },

      _edit(item) {
        console.log("test router ", item)
         this.$router.push({
              name: "apps-e-commerce-product-edit",
              params: { id: item.postId, product: item },
            });
      }
    },

  
  setup() {
   
    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      itemViewOptions,
      itemView,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
